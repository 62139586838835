<template>
  <!-- v-if="$can('read', 'Analytics')" -->
  <div>
    <b-row class="match-height">
      <b-col lg="6" v-if="false">
        <!-- v-if="$can('create', 'Inventory')" -->
        <!--  v-if="$can('read', 'Prices')"-->
        <b-card>
          <b-card-title class="card-header">
            <h4>Lista de puntos</h4>
            <b-button variant="primary" @click="getPoints()">
              <feather-icon icon="RefreshCwIcon" />
            </b-button>
          </b-card-title>
          <h6>Total de puntos: {{ totalPoints }}</h6>
          <b-overlay :show="tableOverlayPoints" rounded="sm">
            <b-table
              hover
              responsive
              :items="lastestPoints"
              :per-page="perPagePointsList"
              :current-page="currentPagePointsList"
              ref="refInvoiceListTable"
              :fields="tableColumnsPointsList"
              primary-key="id"
              show-empty
              empty-text="No hay registros"
              class="position-relative"
              style="height: 35em"
            >
              <!-- Column: Id -->
              <template #cell(id)="data"> #{{ data.value }} </template>
              <template #cell(points)="data"> {{ data.value }} pts </template>

              <!-- Column: Status -->
              <template #cell(price)="data">
                ${{ redondeo(data.value) }}
              </template>
            </b-table>
          </b-overlay>
          <!-- Pagination -->
          <div class="mx-2 mb-2">
            <b-row>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPagePointsList"
                  :total-rows="totalRowsPointsList"
                  :per-page="perPagePointsList"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col lg="6" v-if="false">
        <!-- v-if="$can('create', 'Inventory')" -->
        <!-- v-if="$can('read', 'Prices')"-->
        <b-card>
          <b-card-title class="card-header">
            <h4>Lista de precios</h4>
            <b-button
              id="copyButton"
              variant="gradient-primary"
              size="sm"
              @click="copyText()"
            >
              Copiar
            </b-button>
            <b-tooltip
              target="copyButton"
              :show.sync="showTooltip"
              @shown="hideTooltip"
              triggers
              title="¡Texto copiado!"
              variant="primary"
            >
            </b-tooltip>
          </b-card-title>
          <h5 style="text-align: center;">
            Fecha: {{ new Date(datePriceList) | moment("DD/MM/YYYY, h:mm a") }}
          </h5>
          <b-overlay :show="tableOverlayPricesList" rounded="sm">
            <b-table
              hover
              responsive
              :items="lastestPrices"
              :per-page="perPagePriceList"
              :current-page="currentPagePriceList"
              ref="refInvoiceListTable"
              :fields="tableColumnsPricesList"
              primary-key="id"
              show-empty
              empty-text="No hay registros"
              class="position-relative"
              style="height: 35em"
            >
              <!-- Column: Id -->
              <template #cell(id)="data"> #{{ data.value }} </template>

              <!-- Column: Status -->
              <template #cell(price)="data">
                ${{ redondeo(data.value) }}
              </template>
            </b-table>
          </b-overlay>
          <!-- Pagination -->
          <div class="mx-2 mb-2">
            <b-row>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPagePriceList"
                  :total-rows="totalRowsPriceList"
                  :per-page="perPagePriceList"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-col lg="6" v-if="false">
        <!--v-if="$can('read', 'Prices')"-->
        <b-card>
          <b-card-title class="card-header">
            <h4>Precios de la bolsa</h4>
          </b-card-title>
          <!-- Precio Actual -->
          <h5
            style="text-align:center;"
            v-if="new Date() - dateStockPrices <= 60 * 60 * 24 * 1000"
          >
            Fecha:
            {{ new Date(dateStockPrices) | moment("DD/MM/YYYY, h:mm a") }}
          </h5>
          <!-- Precio Desactualizado -->
          <h5
            style="color: #F97794; text-align:center;"
            v-if="new Date() - dateStockPrices > 60 * 60 * 24 * 1000"
          >
            Fecha:
            {{ new Date(dateStockPrices) | moment("DD/MM/YYYY, h:mm a") }}
          </h5>
          <b-overlay :show="tableOverlayStockPrices" rounded="sm">
            <b-table
              hover
              responsive
              :items="stockPrices"
              :per-page="perPageStockPrices"
              :current-page="currentPageStockPrices"
              :sort-by.sync="sortBy"
              ref="refInvoiceListTable"
              :fields="tableColumnsStockPrices"
              show-empty
              empty-text="No hay registros"
              class="position-relative"
              style="height: 35em"
            >
              <!-- Column: Id -->
              <template #cell(id)="data"> #{{ data.value }} </template>

              <!-- Column: Status -->
              <template #cell(price)="data"> ${{ data.value }} </template>
            </b-table>
          </b-overlay>
          <!-- Pagination -->
          <div class="mx-2 mb-2">
            <b-row>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageStockPrices"
                  :total-rows="totalRowsStockPrices"
                  :per-page="perPageStockPrices"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BAlert,
  BTable,
  BOverlay,
  VBTooltip,
  BPagination,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import data from "@/router/routes/ui-elements";

import store from "@/store";

import userStoreModule from "./userStoreModule";

import utils from "../../utils/utils.js";

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    vSelect,
    BTable,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BPagination,
    BTooltip,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      // Tabla lista de precios
      tableColumnsPricesList: [
        { key: "alias", label: "Item", sortable: true },
        { key: "price", label: "Precio", sortable: true },
      ],
      perPagePriceList: 10,
      totalRowsPriceList: 1,
      currentPagePriceList: 1,
      tableOverlayPricesList: true,
      tableColumnsPointsList: [
        { key: "id", label: "Id", sortable: true },
        { key: "name", label: "Nombre", sortable: true },
        { key: "points", label: "Puntos", sortable: true },
      ],
      perPagePointsList: 10,
      totalRowsPointsList: 1,
      currentPagePointsList: 1,
      tableOverlayPoints: true,
      totalPoints: 0,
      datePriceList: new Date(),
      // Tabla precios de stock
      tableColumnsStockPrices: [
        { key: "name", label: "Nombre", sortable: true },
        { key: "price", label: "Precio", sortable: true },
        { key: "timeseries", label: "Tipo", sortable: true },
      ],
      perPageStockPrices: 10,
      totalRowsStockPrices: 1,
      currentPageStockPrices: 1,
      tableOverlayStockPrices: true,
      sortBy: "timeseries",
      dateStockPrices: new Date(),

      stockPrices: [],
      lastestPrices: [],
      lastestPoints: [],
      time: null,

      // Props
      showTooltip: false,

      // BORRADOR
      option: [
        { name: "Venta", value: "3" },
        { name: "Compra", value: "4" },
      ],
      commodity: [
        { name: "Aluminio", id: "11", value: 0 },
        { name: "Cobre", id: "12", value: 0 },
      ],
      selected: { name: "Venta", value: "3" },
      minerals: [],
      clientPhones: [],
    };
  },
  created() {
    this.getLatestPrices();
    this.getPoints();
    this.getStockPrices();
    this.timer = setInterval(this.getLatestPrices, 60000);
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.timer);
    next();
  },
  methods: {
    getLatestPrices() {
      this.tableOverlayPricesList = true;
      this.$axios
        .get(this.$services + "prices/get_latest_prices")
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          if (!res.data.error) {
            this.datePriceList = parseInt(res.data.data[0].creationDate);
            this.lastestPrices = res.data.data;
            this.totalRowsPriceList = res.data.data.length;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.data,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          this.$verifyToken(error.response.data.data);
        })
        .finally(() => {
          this.tableOverlayPricesList = false;
        });
    },
    getPoints() {
      this.tableOverlayPoints = true;
      this.$axios
        .get(this.$services + "users/get_points")
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          if (!res.data.error) {
            this.totalPoints = res.data.data.reduce(
              (partialSum, a) => partialSum + parseFloat(a.points),
              0
            );
            this.lastestPoints = res.data.data;
            this.totalRowsPointsList = res.data.data.length;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.data,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          this.$verifyToken(error.response.data.data);
        })
        .finally(() => {
          this.tableOverlayPoints = false;
        });
    },
    getStockPrices() {
      this.tableOverlayStockPrices = true;
      this.$axios
        .get(this.$services + "prices/get_Prices", {
          params: {
            operation: 1,
            idStatus: 2,
          },
        })
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          if (!res.data.error) {
            this.dateStockPrices = parseInt(
              res.data.data.currencies[0].creationDate
            );

            // Currencies
            res.data.data.currencies.forEach((element) => {
              this.stockPrices.push({
                name: element.name,
                price:
                  this.formatterNumber(element.commodityPrice) +
                  " " +
                  element.currencyNameTarget,
                timeseries: this.convertTimeSeries(element.timeseries),
              });
            });

            // Stocks
            res.data.data.stocks.forEach((element) => {
              this.stockPrices.push({
                name: element.name,
                price:
                  this.formatterNumber(element.commodityPrice) +
                  " " +
                  element.currencyName +
                  " / " +
                  element.measurementName,
                timeseries: this.convertTimeSeries(element.timeseries),
              });
            });

            this.totalRowsStockPrices = this.stockPrices.length;
          }
        })
        .catch((error) => {
          this.$verifyToken(error.response.data.data);
        })
        .finally(() => {
          this.tableOverlayStockPrices = false;
        });
    },
    convertTimeSeries(value) {
      let timeseries = "";
      if (value > 0) {
        timeseries = "Promedio de " + value + " dias";
      } else {
        timeseries = "Hoy";
      }
      return timeseries;
    },
    redondeo(value) {
      return utils.redondeo(value);
    },
    copyText() {
      // Copiar precios

      let texto = "";

      this.lastestPrices.forEach((element) => {
        texto += element.alias + " $" + utils.redondeo(element.price) + "\n";
      });

      if (texto != "") {
        texto += "\n*Material planta Mty debe estar preparado/paca/jumbo/corto";
      }

      navigator.clipboard.writeText(texto); // Copiar texto
      this.showTooltip = true;
    },
    hideTooltip() {
      setTimeout(() => {
        this.showTooltip = false;
      }, 2000);
    },
    getClients() {
      // console.log(this.selected.value)
      this.clientPhones = [];
      this.$axios
        .post(this.$services + "bot/getClients", {
          broker: 1,
          group: this.selected.value,
        })
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          // console.log(res.data);
          if (!res.data.error) {
            res.data.data.forEach((element) => {
              this.clientPhones.push(element.phone);
            });
          }
        })
        .catch((error) => this.$verifyToken(error.response.data.data));
    },
    showToast(variant, data) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "👋  " + data,
          variant,
        },
      });
    },
    sendMessage() {
      var role = null;
      if (this.selected.value == 3) {
        role = 1;
      } else if (this.selected.value == 4) {
        role = 2;
      }
      // console.log(role)
      // return
      this.$axios
        .post(this.$services + "bot/sendMessage", {
          minerals: this.minerals,
          role: role,
          // role: this.selected.value,
          clientPhones: this.clientPhones,
          broker: 1,
          status: 6,
        })
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          if (!res.data.error) {
            this.showToast("success", res.data.data);
          } else {
            this.showToast("danger", res.data.data);
          }
          // console.log(res);
        })
        .catch((error) => this.$verifyToken(error.response.data.data));
    },
    selectMineral(item) {
      if (!this.containsObject(item, this.minerals)) {
        // agregar elemento
        this.minerals.push(item);
      } else {
        // quitar el elemento
        var removeIndex = this.minerals
          .map(function(item) {
            return item.id;
          })
          .indexOf(item.id);
        this.minerals.splice(removeIndex, 1);
      }
    },
    containsObject(obj, list) {
      //checar si el elemento esta en el array
      var i;
      for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
          return true;
        }
      }
      return false;
    },
    confirmText() {
      // this.getClients();
      this.$swal({
        title: "¿Estas seguro de mandar el mensaje?",
        text: "Una vez enviado no hay forma de revertirlo.",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Enviar",
        cancelButtonText: "Voy a revisarlo",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // console.log('enviarmsg')
          this.sendMessage();
          this.$swal({
            icon: "success",
            title: "Correcto!",
            text:
              "Tu mensaje ha sido programado para enviar a tu lista de clientes.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    formatterNumber(value) {
      return utils.numberWithCommas(value);
    },
  },
  setup() {
    // const INVOICE_APP_STORE_MODULE_NAME = 'users'
    // // Register module
    // if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, userStoreModule)
  },
};

// <b-form @submit.prevent>
//   <b-row>
//     <!-- Tipo de orden -->
//     <b-col cols="12">
//       <b-form-group
//         label="Tipo de orden"
//         label-for="name"
//       >
//         <v-select
//           v-model="selected"
//           :options="option"
//           @input='getClients()'
//           label="name"
//           :clearable="false"
//         />
//       </b-form-group>
//     </b-col>

//     <!-- Minerales -->
//     <b-col cols="12">
//       <b-form-group
//         label="Minerales"
//         label-for="minerales"
//       >
//         <div
//           v-for="(item, index) in commodity"
//           :id="item.name"
//           :key="index">

//           <div style="display: inline-block; width: 50%;">
//             <b-form-checkbox
//               name="check-button"
//               switch
//               inline
//               @change="selectMineral(item)"
//             >
//               {{item.name}}
//             </b-form-checkbox>
//           </div>

//           <div style="display: inline-block; width: 50%;">
//             <b-form-input
//               type="text"
//               :name=item.name
//               v-model=item.value
//               placeholder="0.00"
//             />
//           </div>
//         <br>
//         <br>
//         </div>
//       </b-form-group>
//       <!-- {{commodity}} -->

//     </b-col>

//     <!-- checkbox -->
//     <!-- <b-col cols="12">
//       <b-form-group>
//         <b-form-checkbox
//           id="checkbox-3"
//           name="checkbox-3"
//           value="Remember_me"
//         >
//           Confirmar
//         </b-form-checkbox>
//       </b-form-group>
//     </b-col> -->

//     <!-- submit and reset -->
//     <b-col cols="12">
//       <b-button
//         v-ripple.400="'rgba(255, 255, 255, 0.15)'"
//         type="submit"
//         variant="primary"
//         class="mr-1"
//         @click="confirmText()"
//       >
//         <!-- @click="showToast('success')" -->
//         Enviar
//       </b-button>
//       <b-button
//         v-ripple.400="'rgba(186, 191, 199, 0.15)'"
//         type="reset"
//         variant="outline-secondary"
//       >
//         Reset
//       </b-button>

//       <!-- <b-button
//         v-ripple.400="'rgba(255, 255, 255, 0.15)'"
//         type="submit"
//         variant="primary"
//         class="mr-1"
//         @click="doLogin()"
//       >
//         Login
//       </b-button>

//       <b-button
//         v-ripple.400="'rgba(255, 255, 255, 0.15)'"
//         type="submit"
//         variant="primary"
//         class="mr-1"
//         @click="doLogout()"
//       >
//         Logout
//       </b-button> -->
//     </b-col>
//   </b-row>
// </b-form>
</script>
